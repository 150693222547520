import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";

const Header = () => {
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false); // State to track processing

  const handleLogin = async () => {
    if (typeof window.ethereum === "undefined") {
      toast.error(
        "MetaMask not detected. Please install MetaMask from https://metamask.io/ to continue.",
        { position: "top-center" }
      );
      return;
    }

    if (isProcessing) {
      toast.info("Please wait while the wallet is connecting...", {
        position: "top-center",
      });
      return;
    }

    setIsProcessing(true); // Disable button while processing

    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      if (accounts && accounts.length > 0) {
        toast.success("Wallet connected successfully!", {
          position: "top-center",
        });

        // Redirect and reload dashboard
        navigate("/dashboard");
        setTimeout(() => {
          window.location.reload(); // Reload the page to reflect changes
        }, 500); // Add a slight delay to ensure navigation completes
      } else {
        toast.error("Wallet connection canceled. Please try again.", {
          position: "top-center",
        });
      }
    } catch (error) {
      if (error.code === -32002) {
        toast.warning(
          "Wallet connection request is already in process. Please check MetaMask.",
          { position: "top-center" }
        );
      } else if (error.code === 4001) {
        toast.error("Connection request rejected. Please try again.", {
          position: "top-center",
        });
      } else {
        toast.error("Error during wallet connection. Please try again.", {
          position: "top-center",
        });
      }
    } finally {
      setIsProcessing(false); // Re-enable button after processing
    }
  };

  return (
    <header className="fixed top-0 w-full z-50">
      <nav className="border-gray-200 shadow-md py-2.5 bg-[#181a1e]">
        <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto">
          <a className="flex items-center" href="/">
            <img src="/logo.png" className="h-12 mr-3" alt="ridebnb" />
            <span className="self-center text-2xl font-bold whitespace-nowrap text-white"></span>
          </a>
          <div className="flex items-center lg:order-2">
            <button
              onClick={handleLogin}
              disabled={isProcessing} // Disable button while processing
              className={`text-white ${
                isProcessing
                  ? "bg-gray-500 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              } focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:focus:ring-blue-400`}
            >
              {isProcessing ? "Connecting..." : "Login"}
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
